<template>
  <v-autocomplete
    v-model="inputVal"
    :loading="loading"
    :items="items"
    :search-input.sync="searchValue"
    cache-items
    :rules="rules"
    hide-details
    dense
    outlined
    multiple
    :label="label"
  ></v-autocomplete>
</template>

<script>
import _ from "lodash";

export default {
  name: 'AsyncMultiSelect',
  props: ['value', 'label', 'rules', 'search', 'items'],
  data() {
    return {
      content: this.value,
      toShow: false,
      loading: false,
      searchValue: '',
      filter: _.debounce(async function(value) {
        this.loading = true;
        await this.search({
          ids: this.inputVal,
          keyword: value
        });
        this.loading = false;
      }, 500)
    }
  },
  computed: {
    inputVal: {
      get() {
       return this.value;
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    async searchValue(val) {
      await this.filter(val);
    }
  },
  methods: {
    reset() {
      this.searchValue = "";
      this.inputVal = null;
    },
  }
}
</script>

