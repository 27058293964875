<template>
  <v-card id="promotion-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          @click="createDialogShow()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-btn
          color="primary"
          class="me-3"
          @click="sendNotifications()"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiSend }}
          </v-icon>
          <span>Разослать</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="contacts"
      :server-items-length="totalCount"
      :loading="loading"
      :page.sync="filters.pageNumber"
      :items-per-page.sync="filters.pageSize"
      :sort-by.sync="filters.sortBy"
      :sort-desc.sync="filters.sortDesc"
      class="text-no-wrap"
    >

      <template #[`item.name`]="{item}">
        {{ item.name }}
      </template>

      <template #[`item.description`]="{item}">
        <span class="text-no-wrap">{{ item.description }}</span>
      </template>

      <template #[`item.time`]="{item}">
        {{ toDateTimeString(item.time) }}
      </template>

      <template #[`item.status`]="{item}">
        {{ getStatusDescription(item.status) }}
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'push-notification-history', params: { id: item.id } }"
              >
                <v-icon size="18">
                  {{ icons.mdiAccount }}
                </v-icon>
              </v-btn>
            </template>
            <span>Пользователи</span>
          </v-tooltip>

          <!-- delete -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on"
                @click="deletePopupShow(item.id, item.name)"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <delete-dialog @delete-confirmed="deletePushNotification" :label="deleteLabel"
                   ref="deletePopup" />
    <push-notification-form :label="'Редактирование пуш уведомления'"
                            :cancellation="updateDialogCancel"
                            :show="updateDialog"
                            :save="updateGroupCriteria"></push-notification-form>
    <push-notification-form :label="'Создание пуш уведомления'" :cancellation="createDialogCancel"
                            :show="createDialog"
                            :save="addGroupCriteria"></push-notification-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFormHandlers } from '@/helpers/forms'
import DeleteDialog from '@/components/delete-dialog'
import { toDateTimeString } from '@/helpers'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiSend,
  mdiAccount,
} from '@mdi/js'
import PushNotificationForm from '@/views/push-notification/push-notification-form'

export default {
  components: { PushNotificationForm, DeleteDialog },
  name: 'PushNotificationList',
  data: () => ({
    createDialog: false,
    updateDialog: false,
    toDateTimeString,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiSend,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
      mdiAccount,
    },
    deleteLabel: '',
    deleteMethod: null,
  }),
  computed: {
    ...mapFormHandlers('pushNotification', [
      'filters.pageNumber',
      'filters.pageSize',
      'filters.searchQuery',
      'filters.sortBy',
      'filters.sortDesc',
    ]),
    ...mapState('pushNotification', {
      contacts: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
    }),
    headers() {
      return [
        { text: 'Наименование', value: 'name' },
        { text: 'Описание', value: 'description' },
        { text: 'Время', value: 'time' },
        { text: 'Статус', value: 'status' },

        {
          text: 'Действия',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ]
    },
  },
  async created() {
    document.title = 'Пуш уведомления'
    await this.fetchPushNotifications()
  },
  mounted() {
  },
  methods: {
    ...mapActions('pushNotification', {
      fetchPushNotifications: 'fetchList',
      deletePushNotification: 'delete',
      sendPushNotifications: 'send',
    }),
    ...mapActions('pushNotification/form', ['create', 'resetForm', 'fetchById', 'update']),
    deletePopupShow(id, name) {
      this.deleteLabel = 'Пуш уведомление ' + name + ' будет удалено.'
      this.$refs.deletePopup.open(id)
    },
    viewAccountPopupShow(id){

    },
    async addGroupCriteria() {
      await this.create()
      this.createDialog = false
    },
    getStatusDescription(status) {
      if (status === 'New') {
        return 'Новая'
      } else if (status === 'Processed') {
        return 'Обработана'
      } else {
        return 'В процессе обработки'
      }
    },
    async updateGroupCriteria() {
      await this.update()
      this.updateDialog = false
    },
    createDialogShow() {
      this.createDialog = true
    },
    async sendNotifications() {
      await this.sendPushNotifications()
    },
    createDialogCancel() {
      this.createDialog = false
    },
    updateDialogCancel() {
      this.updateDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
