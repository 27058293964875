<template>
  <v-dialog
    v-model="show"
    max-width="40vw"
    @click:outside="cancel"
  >
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-title class="justify-center text-h5">
        {{ label }}
      </v-card-title>
      <v-card-text>
        <v-form class="multi-col-validation mt-6" ref="form">
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="name"
                label="Наименование"
                dense
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <date-time-picker
                place-holder="Выберите дату и время"
                v-model="time"
                :rules="[rules.required]"
              >
              </date-time-picker>
            </v-col>


            <v-col
              cols="12"
              md="6"
            >
              <async-multi-select
                v-model="groupCriteriaIds"
                clearable
                :items="groupCriteriaDropdown"
                :search="fetchGroupCriteria"
                label="Группы пользователей">
              </async-multi-select>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <async-multi-select
                v-model="customerIds"
                clearable
                :items="customersDropdown"
                :search="fetchCustomers"
                label="Пользователи">
              </async-multi-select>
            </v-col>

            <v-col
              cols="24"
              md="12"
            >
              <v-file-input
                label="Файл должен содержать телефоны на каждой колонке первой ячейки"
                dense
                @change="fileUploaded"
                accept="text/csv"
                outlined
                v-model="file"
              ></v-file-input>
            </v-col>

            <v-col
              cols="24"
              md="12"
            >
              <v-textarea
                v-model="description"
                label="Описание"
                dense
                outlined
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>

            <v-col
              cols="24"
              md="12"
            >
              <label>Импортированные номера: {{ importedPhoneNumbers }}</label>
            </v-col>

            <v-col cols="12">
              <v-btn
                @click="saveData"
                color="primary"
                class="me-3 mt-4"
              >
                Сохранить
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                @click="cancel"
              >
                Отмена
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import DateTimePicker from '@/components/date-time-picker'
import AsyncMultiSelect from '@/components/async-multi-select'
import { rules } from '@/helpers/validation'

export default {
  name: 'PushNotificationForm',
  components: { DateTimePicker, AsyncMultiSelect },
  props: ['save', 'show', 'cancellation', 'label'],
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    rules,
    importedPhoneNumbers: '',
  }),
  computed: {
    ...mapFormHandlers('pushNotification/form', [
      'name',
      'description',
      'customerIds',
      'file',
      'time',
      'groupCriteriaIds',
    ]),
    ...mapGetters('groupCriteria', { groupCriteriaDropdown: 'dropdown' }),
    ...mapGetters('dictionary', { customersDropdown: 'customerDropdown' }),
  },
  watch: {
    show: function(newValue) {
      if (newValue && this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
  },
  methods: {
    cancel() {
      this.resetForm()
      this.cancellation()
    },
    async fileUploaded(){
        this.importedPhoneNumbers = (await this.previewPhoneNumbers(this.file)).toString()
    },
    ...mapActions('pushNotification/form', ['resetForm', 'previewPhoneNumbers']),
    ...mapActions('groupCriteria', { fetchGroupCriteria: 'fetchDropdown' }),
    ...mapActions('dictionary', { fetchCustomers: 'fetchCustomersDropdown' }),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save()
        this.resetForm()
      }
    },
  },
}
</script>
